/*
* @Author: 曹俊杰
* @Date: 2024-09-22 14:56:53
 * @Last Modified by: 曹俊杰
 * @Last Modified time: 2024-09-22 17:19:44
*/代金券
<template>
    <div class="box">
        <ul class="menu">
            <li v-for="item in menuList" class="item pointer" :class="{ 'def-active': active === item.key }"
                :key="item.key" @click="menuClick(item)">
                {{ item.title }}
                <div class="line"></div>
            </li>
        </ul>

        <div class="content">
            <template v-for="item in 8">
                <div class="card" v-if="item % 3 == 2" :key="item">
                    <div class="card-l">
                        <div class="icon">¥</div>
                        <div class="value">300</div>
                    </div>
                    <div class="card-r">
                        <div class="card-r-t">代金券</div>
                        <div class="card-r-b">2024-23-23 23:23:23 后过期</div>
                    </div>
                </div>
                <!-- 使用 -->
                <div class="card yishiyong" v-else-if="item % 3 == 1" :key="item">
                    <div class="card-l">
                        <div class="icon">¥</div>
                        <div class="value">300</div>
                    </div>
                    <div class="card-r">
                        <div class="card-r-t">代金券（已使用）</div>
                        <div class="card-r-b">2024-23-23 23:23:23 后过期</div>
                    </div>
                </div>
                <!-- 过期 -->
                <div class="card yiguoqi" v-else :key="item">
                    <div class="card-l">
                        <div class="icon">¥</div>
                        <div class="value">300</div>
                    </div>
                    <div class="card-r">
                        <div class="card-r-t">代金券（已过期）</div>
                        <div class="card-r-b">2024-23-23 23:23:23 后过期</div>
                    </div>
                </div>
            </template>

        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            menuList: [{
                title: '未使用',
                key: '0'
            },
            {
                title: '已使用',
                key: '1'
            },
            {
                title: '已过期',
                key: '2'
            }],
            active: '0'
        }
    },
    methods: {
        menuClick({ key }) {
            this.active = key
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
    background: #fff;
    border-radius: 6px;
    flex-direction: column;

    .menu {
        flex: 1;
        display: flex;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;
        height: 60px;
        padding: 0 20px;

        .item {
            margin-right: 40px;
            position: relative;
        }

        .def-active {
            color: #0062FF;

            .line {
                display: block;
                background: #0062FF;
            }
        }



        .line {
            width: 16px;
            height: 2px;
            border-radius: 8px;
            position: absolute;
            bottom: 10px;
            left: 0;
            right: 0;
            margin: auto;
            display: none;
        }
    }

    .content {
        padding: 10px 20px;
        padding-right: 0;
        display: flex;
        flex-wrap: wrap;
        width: 870px;

        .card {
            width: 410px;
            height: 116px;
            border-radius: 10px;
            border: #eee;
            background-color: #F3F6F8;
            background-image: url(~@/static/image/accountCenter/accountCenter_bg_card.png);
            background-size: 125px 96px;
            background-repeat: no-repeat;
            background-position: 16px center;
            display: flex;
            color: #0062FF;
            margin-bottom: 30px;
            // margin-right: 30px;

            &:nth-child(2n - 1) {
                margin-right: 30px;
            }

            &-l {
                width: 156px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;

                .icon {
                    margin-top: 15px;
                }

                .value {
                    font-size: 46px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            &-r {
                display: flex;
                padding: 0 10px;
                justify-content: center;
                flex-direction: column;

                &-t {
                    font-family: "Source Han Sans CN";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                &-b {
                    margin-top: 6px;
                    color: #262626;
                    font-family: "Source Han Sans CN";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

        .yishiyong {
            .card-r {
                &-t {
                    color: #666;
                }

                &-b {
                    color: #999;
                }
            }
        }

        .yiguoqi {
            color: #999 !important;

            .card-r-b {
                color: #999 !important;
            }
        }

    }
}
</style>